import * as React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import Calendar from '../components/calendar'
import LessonsCardRequest from '../components/lessons-card-request'
// import MemberDataRequest from '../components/member-data-request'

const InscriptionAuxCours = () => {
    const pageTitle = 'Inscription aux cours';
    
    return (
        <>
            <Helmet>
                <title>Educa'dog : Centre d'éducation positive à Léglise - Inscription aux cours</title>
                <descrtiption>Educa'dog est un centre d'éducation canine positive situé chemin du Stria à Léglise, en province de Luxembourg. Nous n'utilisons que les méthodes positive et respectueuses du chien.</descrtiption>
            </Helmet>
            <Layout>
                <LessonsCardRequest /><br />
                {/* <MemberDataRequest /><br /> */}
                <Calendar />
            </Layout>
        </>
    )
}

export default InscriptionAuxCours