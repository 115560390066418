import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import getConfig from '../utils/get-config'
import IsMaxPeoplePicture from '../images/dog-sad.svg'

const EventModal = props => {
    const [showState, setShowState] = useState(props)
    const [memberIdErrorState, setMemberIdErrorState] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, touchedFields, submitCount }
    } = useForm()
    const [rememberMe, setRememberMe] = useState(false)

    const onSubmit = (data, event) => {
        if (data['member_id'] !== '' && data['member_id'].length === 6 && data['event_id'] !== '') {
            axios.post(`${getConfig('webServciesUrl')}event-registration.php`, data).then(response => {
                if (response.data[0] === 'success') {
                    let modalSuccesContainer = event.target.querySelector('.modal-success')
                    modalSuccesContainer.classList.add('show')
                    modalSuccesContainer.innerHTML = modalSuccesContainer.innerHTML + response.data[1]
                }
                setMemberIdErrorState(response.data[0] !== 'success')
            }).catch(function (error) {
                console.log(error)
            })
        } else
            setMemberIdErrorState(true)
    }

    const onlyNumberKey = (event) => {
        event.target.value = event.target.value.replace(/\D/g,'')
    }

    const getHourFromEventDate = (eventDate) => {
        let date = eventDate.split(';')
        let hour = date[0].split('-')[1]
        hour = `${hour.split(':')[0]}:${hour.split(':')[1]}`

        return hour
    }

    const setFocus = (memberIdFieldId) => {
        setTimeout(() => {
            document.getElementById(memberIdFieldId).focus()
        }, 1)
    }

    useEffect(() => {
        if (showState !== props)
            setShowState(props)
    }, [showState])

    return (
        <div className={`modal${props.show ? ' show' : ''}${props.event.is_max_people ? ' is-max-people ' : ''}`}>
            {!props.event.is_max_people &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FontAwesomeIcon className="close-modal" icon={faTimesCircle} onClick={props.onClose} />
                    <span className="modal-title">
                        Inscription à "{props.event.title}" à {getHourFromEventDate(props.event.date)}
                    </span>
                    <label htmlFor={`member_id_${props.event.id}`}>
                        Entrez votre <strong>numéro de membre</strong> (reçu par email) pour vous inscrire :
                    </label>
                    <input type="text" id={`member_id_${props.event.id}`} name="member_id" maxLength="6" onKeyUp={onlyNumberKey} autoComplete="off" {...register('member_id')} /><br />
                    {/* <input id={'test'} type="checkbox" name="remember-me" value="true" checked={rememberMe} />
                    <label>
                        Se souvenir de mon numéro de membre pour les prochaines fois
                    </label> */}
                    {props.show ? setFocus(`member_id_${props.event.id}`) : null}
                    <input type="hidden" name="event_id" value={props.event.id} {...register('event_id')}  />
                    <span class={`error ${memberIdErrorState ? 'show' : ''}`}>
                        Veuillez entrer un numéro de membre valide
                    </span>
                    <span className="tip">
                        <FontAwesomeIcon className="icon" icon={faExclamationTriangle}/> Si vous avez <strong>plusieurs chiens</strong> qui viennent à ce cours, pensez à vous inscrire <strong>plusieurs fois</strong>.
                    </span>
                    <button type="submit" className="button primary">
                        M'inscrire à ce cours
                    </button>
                    <div className="modal-success">
                        <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                        Votre inscription a bien été enregistrée et vous sera confirmée par email<br />
                    </div>
                </form>
            }
            {props.event.is_max_people &&
                <>
                    <span className="modal-title center">
                        <img src={IsMaxPeoplePicture} alt="Chien triste" /><br />
                        Malheureusement il n'est plus possible de s'inscrire à cet évènement.<br />
                        <span class="small">Le nombre maximum d'inscrits a été atteint !</span>
                    </span>
                    <span className="modal-explain">
                        Ceci afin de vous garantir un accueil personnalisé et une qualité de cours toujours optimale, nous nous devons de limiter le nombre de participants aux cours.
                    </span>
                </>
            }
        </div>
    )
}

export default EventModal