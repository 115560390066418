import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faTimesCircle, faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import getConfig from '../utils/get-config'

import EventModal from './event-modal'

const Calendar = () => {
    const [data, setData] = useState([])
    const [eventModalShown, setEventModalShown] = useState(null)

    let calendar = null,
        previousMonthButton = null,
        nextMonthButton = null,
        currentMonthLabel,
        currentYearLabel,
        grid = null,
        boxes = []
    const days = [ 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche' ],
          months = [ 'janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre' ],
          today = new Date()
    let currentMonth = today.getMonth(),
        currentYear = today.getFullYear()

    const zeroBegin = (number) => number < 10 ? `0${String(number)}` : number

    const fillCalendar = () => {
        let daysInMonthDate = new Date(currentYear, currentMonth, 1)
            daysInMonthDate.setMonth(daysInMonthDate.getMonth() + 1)
        let daysInMonth = new Date(daysInMonthDate.getFullYear(), daysInMonthDate.getMonth(), 0).getDate()
        let firstDayOfWeek = new Date(currentYear, currentMonth, 1).getDay()
            firstDayOfWeek = firstDayOfWeek == 0 ? 6 : firstDayOfWeek-1
        let gridBoxes = 0
        let previousMonthLastDay = new Date(currentYear, currentMonth, 0).getDate()
        let previousMonthNumber = new Date(currentYear, currentMonth, 1)
            previousMonthNumber.setMonth(previousMonthNumber.getMonth() - 1)
        let previousMonthYear = previousMonthNumber.getFullYear()
            previousMonthNumber = previousMonthNumber.getMonth()
        let nextMonthNumber = new Date(currentYear, currentMonth, 1)
            nextMonthNumber.setMonth(nextMonthNumber.getMonth() + 1)
        let nextMonthYear = nextMonthNumber.getFullYear()
            nextMonthNumber = nextMonthNumber.getMonth()

        boxes = []

        let previousMonthDay = previousMonthLastDay - (firstDayOfWeek - 1)
        let startDate = `${zeroBegin(previousMonthDay)}-${zeroBegin(previousMonthNumber+1)}-${previousMonthYear}`
        for (let i = 1; i <= firstDayOfWeek; i++) {
            boxes.push({
                date: `${zeroBegin(previousMonthDay)}/${zeroBegin(previousMonthNumber+1)}/${previousMonthYear}`,
                dayNumber: previousMonthDay,
                cssClass: 'previous-month',
                events: []
            })
            gridBoxes++
            previousMonthDay++
        }

        for (let day = 1; day <= daysInMonth; day++) {
            let dayName = new Date(currentYear, currentMonth, day).getDay()
                dayName = dayName == 0 ? 6 : dayName-1
                dayName = days[dayName]
            boxes.push({
                date: `${zeroBegin(day)}/${zeroBegin(currentMonth+1)}/${currentYear}`,
                dayNumber: day,
                dayName: dayName,
                cssClass: 'current-month',
                events: []
            })
            gridBoxes++
        }

        let nextMonthDay = 1
        while (gridBoxes <= 41) {
            boxes.push({
                date: `${zeroBegin(nextMonthDay)}/${zeroBegin(nextMonthNumber+1)}/${nextMonthYear}`,
                dayNumber: nextMonthDay,
                cssClass: 'next-month',
                events: []
            })
            gridBoxes++
            nextMonthDay++
        }
        let endDate = `${zeroBegin(nextMonthDay)}-${zeroBegin(nextMonthNumber+1)}-${nextMonthYear}`
        
        axios.get(`${getConfig('webServciesUrl')}get-event.php?start=${startDate}&end=${endDate}`).then(response => {
            const events = response.data
            events.forEach((event) => {
                let foundBox = null
                foundBox = boxes.forEach(box => {
                    if (event.date.substring(0, box.date.length) === box.date)
                        box.events.push(event)
                })
            })

            setData(boxes)
        }).catch(function (error) {
            console.log(error)
        })
    }

    const changeMonth = (modifier) => {
        let newDate = new Date(currentYear, currentMonth, 1)
            newDate.setMonth(newDate.getMonth() + modifier)
        currentMonth = newDate.getMonth()
        currentYear = newDate.getFullYear()
        currentMonthLabel.innerText = months[currentMonth]
        currentYearLabel.innerText = currentYear

        fillCalendar()
    }

    const showEventModal = (eventId) => {
        setEventModalShown(eventId)
    }

    const closeEventModal = () => {
        setEventModalShown(null)
    }

    useEffect(() => {
        calendar = document.getElementById('calendar')
        grid = calendar.querySelector('.grid')

        previousMonthButton = calendar.querySelector('.previous-month-button')
        previousMonthButton.addEventListener('click', function() {
            changeMonth(-1)
        })

        nextMonthButton = calendar.querySelector('.next-month-button')
        nextMonthButton.addEventListener('click', function() {
            changeMonth(1)
        })
        
        currentMonthLabel = calendar.querySelector('.current-month-label')
        currentYearLabel = calendar.querySelector('.current-year-label')

        fillCalendar()
    }, [])

    return (
        <section id="calendar">
            <div className="container">
                <h2 className="title">
                    Calendrier des cours &amp; activités
                </h2>
                <div className="nav">
                    <button className="previous-month-button">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <div className="labels">
                        <span className="current-month-label">
                            {months[currentMonth]}
                        </span>
                        <span className="current-year-label">
                            {currentYear}
                        </span>
                    </div>
                    <button className="next-month-button">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
                <div className="header">
                    {days.map((label, index) => {
                        return (
                            <div className="day" key={index}>
                                {label}
                            </div>
                        )
                    })}
                </div>
                <div className="grid">
                    {data.map((box, index) => {
                        return (
                            <div className={`box${box.cssClass ? ` ${box.cssClass}` : ''}`} data-date={box.date} key={index}>
                                <div className="day">
                                    {box.dayName &&
                                        <span className="name">
                                            {box.dayName}
                                        </span>
                                    }
                                    <span className="number">
                                        {box.dayNumber}
                                    </span>
                                </div>
                                {box.events.length > 0 &&
                                    <ul className="events">
                                        {box.events.map((event, index) => {
                                            return (
                                                <li className={event.type} key={event.id}>
                                                    <button className="event-button" onClick={() => { showEventModal(event.id) }}>
                                                        {`${event.title}${event.hour !== '' ? ' ' + event.hour : ''}`}
                                                    </button>
                                                    <EventModal event={event} show={eventModalShown === event.id} onClose={closeEventModal} />
                                                    <div className={eventModalShown === event.id ? "modal-overlay show" : "modal-overlay"} onClick={closeEventModal}></div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Calendar