import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import getConfig from '../utils/get-config'

const LessonsCardRequest = () => {
    const [data, setData] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalSuccess, setModalSuccess] = useState(false)
    const [modalError, setModalError] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, touchedFields, submitCount }
    } = useForm()

    const onSubmit = (data) => {
        if (data["member_id"] !== '') {
            axios.post(`${getConfig('webServciesUrl')}request-lessons-card.php`, data).then(response => {
                console.log(response.data[0])
                if (response.data[0] === 'success') {
                    console.log('axios success')
                    setModalSuccess(true)
                    setModalError(false)
                } else {
                    setModalSuccess(false)
                    setModalError(true)
                }
            }).catch(function (error) {
                console.log(error)
            })
        }
    }

    const onlyNumberKey = (event) => {
        event.target.value = event.target.value.replace(/\D/g,'')
    }

    useEffect(() => {
        let memberIdField = document.getElementById('lessons-card-request').querySelector('.modal input[name="member_id"]')
        if (modalOpen) {
            memberIdField.focus()
            memberIdField.value = ''
            setModalSuccess(false)
            setModalError(false)
        }
        document.getElementById('main').classList.toggle('modal-open', modalOpen)
    }, [modalOpen])

    return (
        <section id="lessons-card-request">
            <button className="button dark" onClick={() => setModalOpen(true)}>
                Demander une carte de 10 leçons + 1 gratuite
            </button>
            {modalOpen &&
                <>
                    <div className={`modal${modalOpen ? ' show' : ''}`}>
                        <FontAwesomeIcon className="close-modal" icon={faTimesCircle} onClick={() => setModalOpen(false)} />
                        <span className="modal-title">
                            Demander une carte de 10 leçons + 1 gratuite
                        </span>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label htmlFor="member_id">
                                Entrez votre <strong>numéro de membre</strong> :
                            </label>
                            <input type="text" id="member_id" name="member_id" maxLength="6" onKeyUp={onlyNumberKey} autoComplete="off" {...register("member_id")}  />
                            <span className="tip">
                                <FontAwesomeIcon className="icon" icon={faExclamationTriangle}/> Vous recevrez les informations de paiement <strong>par email</strong> sur l'adresse <strong>enregistrée lors de votre inscription</strong>.
                            </span>
                            <button type="submit" className="button primary">
                                Faire la demande
                            </button>
                        </form>
                        <div className={`modal-success${modalSuccess ? ' show' : ''}`}>
                            Votre demande à bien été enregistrée et vous sera confirmée par email avec les informations de paiement
                        </div>
                        <div className={`modal-error${modalError ? ' show' : ''}`}>
                            Le numéro de membre n'a pas été trouvé.<br />
                            Veuillez utiliser le numéro envoyé lors de votre inscription ou présent sur votre carte de membre.
                        </div>
                    </div>
                    <div className={`modal-overlay${modalOpen ? ' show' : ''}`} onClick={() => setModalOpen(false)}></div>
                </>
            }
        </section>
    )
}

export default LessonsCardRequest